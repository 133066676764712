import React, { useState, useEffect } from 'react';
import './styles.scss';
import { Link } from 'react-router-dom';

import logo from '../../assets/images/logo/logo.png';
import logodark from '../../assets/images/logo/aspn_logo.png';

import bank from '../../assets/images/aspn/bank.png'
import './styles.scss'



function Footer(props) {

    const [productLink] = useState([
        {
            title: 'Spot',
            path: '#'
        },
        {
            title: 'Inverse Perpetual',
            path: '#'
        },
        {
            title: 'USDT Perpetual',
            path: '#'
        },
        {
            title: 'Exchange',
            path: '#'
        },
        {
            title: 'Launchpad',
            path: '#'
        },
        {
            title: 'Binance Pay',
            path: '#'
        },
    ]);
    const [servicesLink] = useState([
        {
            title: 'Buy Crypto',
            path: '#'
        },
        {
            title: 'Markets',
            path: '#'
        },
        {
            title: 'Tranding Fee',
            path: '#'
        },
        {
            title: 'Affiliate Program',
            path: '#'
        },
        {
            title: 'Referral Program',
            path: '#'
        },
        {
            title: 'API',
            path: '#'
        },
    ]);


    const [listSocial] = useState([
        {
            icon: 'icon-facebook-f',
            path: '#'
        },
        {
            icon: 'icon-instagram',
            path: '#'
        },
        {
            icon: 'icon-youtube',
            path: '#'
        },
        {
            icon: 'icon-twitter',
            path: '#'
        },
    ])


    const [isVisible, setIsVisible] = useState(false);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    useEffect(() => {
        const toggleVisibility = () => {
            if (window.pageYOffset > 500) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        window.addEventListener("scroll", toggleVisibility);

        return () => window.removeEventListener("scroll", toggleVisibility);
    }, []);

    return (

        <footer className="footer">
            <div className="tf-container">
                <div className="row">
                    <div className="col-xl-4 col-lg-3 col-md-3">
                        <div className="widget widget-infor">
                            <div className="logo">
                                <img id="logo_footer" src={bank} alt='Bank Image' className='logo-dark' /><br/>
                                <img id="logo_footer" className='logo-dark' src={logodark} alt="Allspin168" />
                                <img id="logo_footer" className='logo-light' src={logo} alt="Allspin168" />
                            </div>
                            <p className="content">เว็บตรงลิขสิทธ์แท้จากต่างประเทศรวบรวมค่ายดังจากทั่วทุกมุมโลก บริการระดับพรีเมี่ยม มาพร้อมกับระบบ ฝาก-ถอน อัตโนมัติ 15 วิ</p>
                            <ul className="social-item">
                                <li><Link to="#"><i className="fab fa-twitter"></i></Link></li>
                                <li><Link to="#"><i className="fab fa-facebook"></i></Link></li>
                                <li><Link to="#"><i className="fab fa-telegram-plane"></i></Link></li>
                                <li><Link to="#"><i className="fab fa-youtube"></i></Link></li>
                                <li><Link to="#"><i className="icon-fl-tik-tok-2"></i></Link></li>
                                <li><Link to="#"><i className="icon-fl-vt"></i></Link></li>
                            </ul>
                            <p className="copy-right">Copyright © 2024 Allspin168. All Rights Reserved.

                            </p>
                        </div>
                    </div>
                    <div className="col-xl-5 col-lg-6 col-md-5">
                        <div className="widget widget-menu">
                            <div className="menu menu-1">
                                <h6 className="widget-title">สล็อต</h6>
                                <ul >
                                    <li><Link to="/slot">918KISS</Link></li>
                                    <li><Link to="/slot">ambslot</Link></li>
                                    <li><Link to="/slot">bolebit</Link></li>
                                </ul>
                            </div>
                            <div className="menu menu-2">
                                <h6 className="widget-title">คาสิโน</h6>
                                <ul >
                                    <li><Link to="/casinos">ae</Link></li>
                                    <li><Link to="/casinos">allbet</Link></li>
                                    <li><Link to="/casinos">b-live-yeebet</Link></li>
                                </ul>
                            </div>
                            <div className="menu menu-3">
                                <h6 className="widget-title">เดิมพันกีฬา</h6>
                                <ul >
                                    <li><Link to="/sports">GOAT SPORT</Link></li>
                                    <li><Link to="/sports">TFGAMING</Link></li>
                                </ul>
                            </div>
                            <div className="menu menu-4">
                                <h6 className="widget-title">หวย</h6>
                                <ul >
                                    <li><Link to="/lottos">HUAY</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-4">
                        <div className="widget widget-subcribe">
                            <h6 className="widget-title">ติดต่อ</h6>
                            <a href="https://allspin168.electrikora.com/?action=register">
                                <p className="content">ติดต่อสมัครใช้งานได้ที่ Line คลิก...</p>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            {
                isVisible &&
                <Link onClick={scrollToTop} to='#' id="scroll-top"></Link>
            }
        </footer>
    );
}

export default Footer;