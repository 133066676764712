import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import img from '../assets/images/contract/qrcode.png'

Contact.propTypes = {
    
};

function Contact(props) {
    return (
        <div>

        <section className="tf-page-title style-2">    
            <div className="tf-container">
                <div className="row">
                    <div className="col-md-12">
                        <ul className="breadcrumbs">
                            <li><Link to="/">Home</Link></li>
                            <li>ติดต่อ</li>
                        </ul>
                
                    </div>
                </div>
            </div>                    
        </section>
                
        <section className="tf-contact">
            <div className="tf-container">
                <div className="row ">
                    <div className="col-md-6">
                        <div className="image ani4">
                            <a href="https://lin.ee/gz5a3Ws">
                                <img src={img} alt="Allspin168" />
                            </a>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="tf-heading style-3">
                            <h4 className="heading">ติดต่อสอบถามข้อมูลเพิ่มเติม</h4>
                            <a href="https://lin.ee/gz5a3Ws">
                                <h6 className="sub-heading">คลิกที่รูปเพื่อติดต่อ Line@...</h6>
                            </a>
                            <p className="sub-heading">หากคุณต้องการข้อมูลเพิ่มเติมหรือมีคำถามเกี่ยวกับ Allspin168 หรือบริการใด ๆ ที่เกี่ยวข้อง คุณสามารถติดต่อฝ่ายบริการลูกค้าของ Allspin168 ได้ทางช่องทางต่าง ๆ ดังนี้:</p><br/>
                            <p className="sub-heading">เว็บไซต์ Allspin168: คุณสามารถเข้าชมเว็บไซต์ Allspin168 เพื่อดูข้อมูลเพิ่มเติมและใช้บริการต่าง ๆ ได้ที่</p><br/>
                            <p className="sub-heading">แชทสด: ในไลน์ Allspin168 นั้นมีบริการแชทสดที่คุณสามารถใช้สอบถามและติดต่อกับทีมงานได้ ตาม QR Code</p>
                        </div>                    
                    </div>
                </div>
            </div>
        </section>
            
        </div>
    );
}

export default Contact;