import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Link } from 'react-router-dom';
import icon1 from '../../assets/images/icon/rain1.svg'
import icon2 from '../../assets/images/icon/rain2.svg'
import CardModal from '../layouts/CardModal';

Casino_Live.propTypes = {
    data: PropTypes.array,
};

function Casino_Live(props) {
    const [modalShow, setModalShow] = useState(false);
    const { data } = props;

    const [dataTab] = useState([
        // {
        //     id: 1,
        //     title: 'สล็อตออนไลน์',
        //     item: 1,
        // },
        {
            id: 2,
            title: 'คาสิโนสด',
            item: 0,
        },
        // {
        //     id: 3,
        //     title: 'เดิมพันกีฬา',
        //     item: 3,
        // },
        // {
        //     id: 4,
        //     title: 'หวยออนไลน์',
        //     item: 4,
        // },

    ]);
    return (
        <section className="tf-section tf-hot-auction tf-filter">
            <div className="tf-container">
                <div className="row ">
                    <div className="col-md-12">
                        <Tabs>
                            <div className="d-flex justify-content-between mb-wr">
                                <TabList>
                                    {
                                        dataTab.map(idx => (
                                            <Tab key={idx.id}>{idx.title}</Tab>
                                        ))
                                    }

                                </TabList>
                           </div>
                            {
                                dataTab.map(idx => (
                                    <TabPanel key={idx.id}>
                                        <div className="row tf-filter-container wow fadeInUp">
                                            {
                                                data.slice(idx.item, 35).map(idx => (
                                                    <div key={idx.id} className="col-xl-3 col-lg-4 col-md-6 col-sm-6 tf-loadmore 3d cyber">
                                                        <div className="sc-product style1">
                                                            <div className="top">
                                                                <Link to="/item-details-v1" className="tag">{idx.title}</Link>
                                                                <div className="wish-list">
                                                                    <Link to="#" className="heart-icon"></Link>
                                                                </div>
                                                            </div>
                                                            <div className="features">
                                                                <div className="product-media">
                                                                    <img src={idx.img} alt="images" />
                                                                </div>
                                                                <div className="featured-countdown">
                                                                    <span className="js-countdown" data-timer="55555" data-labels=" ,  h , m , s "></span>
                                                                </div>
                                                                <div className="rain-drop1"><img src={icon1} alt="images" /></div>
                                                                <div className="rain-drop2"><img src={icon2} alt="images" /></div>
                                                            </div>
                                                            <div className="bottom">
                                                                <div className="details-product">
                                                                    <div className="author">
                                                                        {/* <div className="avatar">
                                                                            <img src={idx.avt} alt="images" />
                                                                        </div> */}
                                                                        <div className="content">
                                                                            <div className="position">Creator</div>
                                                                            <div className="name"> <Link to="#">Allspin168</Link></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="product-button">
                                                                    {/* <Link to="#" onClick={() => setModalShow(true)} data-toggle="modal" data-target="#popup_bid" className="tf-button"> <span className="icon-btn-product"></span>รายละเอียด</Link> */}
                                                                    <Link to="https://lin.ee/gz5a3Ws" data-toggle="modal" data-target="#popup_bid" className="tf-button"> <span className="icon-btn-product"></span>รายละเอียด</Link>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }

                                        </div>
                                    </TabPanel>
                                ))
                            }
                        </Tabs>

                    </div>
                </div>
            </div>

            <CardModal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </section>
    );
}

export default Casino_Live;