import React from 'react';
import PropTypes from 'prop-types';
import PageTitle from '../components/pagetitle/PageTitle';
import Detail2 from '../components/promotions/detail2';
import dataEvents from '../assets/fake-data/data-Events';

SpecialEvents.propTypes = {
    
};

function SpecialEvents(props) {
    return (
        <div className='page-promotion'>
            <PageTitle sub='กิจกรรมพิเศษ' title='กิจกรรมพิเศษ' />
            <Detail2 data={dataEvents} />
        </div>
    );
}

export default SpecialEvents;