import React from 'react';
import { Link } from 'react-router-dom';
import img from '../../assets/images/banner/details4.png'
import shape1 from '../../assets/images/aspn/phone1 583-643.png'
import shape4 from '../../assets/images/aspn/chip.png'

Exgame.propTypes = {
};
function Exgame(props) {
    return (
        <section className="tf-section tf-banner-aspn ASPN" >
            <div className="tf-container">
                <div className="row vertical-middle">
                    <div className="col-md-6">
                        <div className="content-right">
                            <div className="thumb">
                                <img className="ani4" src={shape1} alt="images" />
                                <img className="details-thumb3 ani5" src={shape4} alt="images" />
                                <img className="details-thumb4" src={img} alt="images" />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="content-banner">
                            <h2 className="wow fadeInUp">เว็บตรง<span>ลิขสิทธ์แท้</span>จากต่างประเทศ</h2>
                            <p className="wow fadeInUp">รวบรวมค่ายดังจากทั่วทุกมุมโลก บริการระดับพรีเมี่ยม มาพร้อมกับระบบ ฝาก-ถอน อัตโนมัติ 15 วิ</p>
                            <div className="group-btn wow fadeInUp">
                                <Link to="https://allspin168.electrikora.com/?action=register" className="tf-button style-2 btn-1">สมัครสมาชิก</Link>
                                <Link to="https://allspin168.electrikora.com/" className="tf-button style-3 btn-2">เข้าสู่ระบบ</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default Exgame;