import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import CardModal from '../layouts/CardModal';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';

Camp.propTypes = {
    data: PropTypes.array,
};

function Camp(props) {

    const { data } = props;

    const [modalShow, setModalShow] = useState(false);

    const [dataTab] = useState([
        {
            id: 1,
            title: 'สล็อตออนไลน์',
            item: 1,
        },
        {
            id: 2,
            title: 'คาสิโนสด',
            item: 2,
        },
        {
            id: 3,
            title: 'เดิมพันกีฬา',
            item: 3,
        },
        {
            id: 4,
            title: 'หวยออนไลน์',
            item: 4,
        },

    ]);
    return (
        <section className="tf-section tf-explore tf-filter tf-center">
            <div className="tf-container">
                <div className="row ">
                    <div className="col-md-12">
                        <div className="tf-heading style-2 wow fadeInUp">
                            <h4 className="heading">ค่ายเกมส์</h4>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <Tabs>
                            <div className="d-flex justify-content-between mb-wr">
                                <TabList>
                                    {
                                        dataTab.map(idx => (
                                            <Tab key={idx.id}>{idx.title}</Tab>
                                        ))
                                    }

                                </TabList>
                            </div>
                            {
                                dataTab.map(idx => (
                                    <TabPanel key={idx.id}>
                                        <div className="row tf-filter-container wow fadeInUp">
                                            {
                                                data.slice(idx.item, 8).map(idx => (
                                                    <div key={idx.id} className="col-xl-3 col-lg-4 col-md-6 col-sm-6 tf-loadmore 3d pixel">
                                                        <div className="sc-product style3">
                                                            <div className="features">
                                                                <div className="product-media">
                                                                    <a href="https://allspin168.electrikora.com/?action=register" target="_blank" rel="noopener noreferrer">
                                                                        <img src={idx.img} alt="images" />
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </TabPanel>
                                ))
                            }


                        </Tabs>
                    </div>
                    <div className="col-md-12">
                        <div className="btn-loadmore mt8 wow fadeInUp">
                            <Link to="/slot" className="tf-button loadmore style-4">Load more</Link>
                        </div>
                    </div>
                </div>
            </div>

            <CardModal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </section>
    );
}

export default Camp;