import avt1 from '../images/author/author1.png'

import img1 from '../images/aspn/game-camp/huay/HUAY.jpg'

const dataHuay = [
    {
        id: 1,
        img: img1,
        title: 'HUAY',
        create: 'Allspin168',
        price: '',
        avt: avt1,
    },
];

export default dataHuay;